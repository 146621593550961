.btn {
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
  border-width: 1px;
  border-radius: var(--button-border-radius-sm);
  color: var(--color-gray-800);
  font-size: 1rem;
  font-weight: 300;
  margin-bottom: 0.32em;
  margin-top: 0.32em;
  padding: 0.48em 1.32em 0.32em 1.32em;
  will-change: box-shadow;
}

.btn:focus-visible {
  --outline-offset: -0.32em;
}

/* Button sizes */
.btn-sm {
  font-size: 0.9rem;
  padding: 0.24em 0.98em 0.18em 0.98em;
}

.btn-lg {
  font-size: 1.125rem;
  padding: 0.64em 2em 0.48em 2em;
}

/* Button shapes */
.btn-pill {
  border-radius: 9999px;
}

.btn-round {
  border-radius: var(--button-border-radius-lg);
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-circle {
  border-radius: 50%;
  padding: 16px;
  text-align: center;
}

.btn-circle.btn-xs {
  padding: 8px;
}

/* Button colors */
.btn-primary,
.btn-info,
.btn-warning,
.btn-success,
.btn-danger {
  color: var(--color-white);
}


.btn-primary {
  background-color: var(--color-primary-700);
  border-color: var(--color-primary-800);
  color: var(--color-primary-100);
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background-color: var(--color-primary-800);
  border-color: var(--color-primary-900);
}

.btn-info {
  background-color: var(--color-info-700);
  border-color: var(--color-info-800);
  color: var(--color-info-100);
}

.btn-info:hover,
.btn-info:active,
.btn-info:focus {
  background-color: var(--color-info-800);
  border-color: var(--color-info-900);
}

.btn-warning {
  background-color: var(--color-warning-700);
  border-color: var(--color-warning-800);
  color: var(--color-warning-100);
}

.btn-warning:hover,
.btn-warning:active,
.btn-warning:focus {
  background-color: var(--color-warning-800);
  border-color: var(--color-warning-900);
}

.btn-danger {
  background-color: var(--color-danger-700);
  border-color: var(--color-danger-800);
  color: var(--color-danger-100);
}

.btn-danger:hover,
.btn-danger:active,
.btn-danger:focus {
  background-color: var(--color-danger-800);
  border-color: var(--color-danger-900);
}

.btn-success {
  background-color: var(--color-success-700);
  border-color: var(--color-success-800);
  color: var(--color-success-100);
}

.btn-success:hover,
.btn-success:active,
.btn-success:focus {
  background-color: var(--color-success-800);
  border-color: var(--color-success-900);
}

/* Buttons look like links */
.btn-link {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  margin: 0;
  padding: 0;
  will-change: unset;
}

.btn-link:active,
.btn-link:focus,
.btn-link:hover {
  text-decoration: underline;
}

/* Button states */
.btn[disabled] {
  background-color: var(--color-gray-800);
  border-color: var(--color-gray-200);
  box-shadow: none;
  color: var(--color-gray-200);
}

/* Buttons with outlines */
.btn-outline {
  background-color: transparent;
}

.btn-outline.btn-info {
  border-color: var(--color-info-800);
  color: var(--color-info-800);
}

.btn-outline.btn-info:hover,
.btn-outline.btn-info:active,
.btn-outline.btn-info:focus {
  background-color: var(--color-info-800);
  color: var(--color-white);
}

.btn-outline.btn-primary {
  border-color: var(--color-primary-800);
  color: var(--color-primary-800);
}

.btn-outline.btn-primary:hover,
.btn-outline.btn-primary:active,
.btn-outline.btn-primary:focus {
  background-color: var(--color-primary-800);
  color: var(--color-white);
}

.btn-outline.btn-warning {
  border-color: var(--color-warning-800);
  color: var(--color-warning-800);
}

.btn-outline.btn-warning:hover,
.btn-outline.btn-warning:active,
.btn-outline.btn-warning:focus {
  background-color: var(--color-warning-800);
  color: var(--color-white);
}

.btn-outline.btn-success {
  border-color: var(--color-success-800);
  color: var(--color-success-800);
}

.btn-outline.btn-success:hover,
.btn-outline.btn-success:active,
.btn-outline.btn-success:focus {
  background-color: var(--color-success-800);
  color: var(--color-white);
}

.btn-outline.btn-danger {
  border-color: var(--color-danger-800);
  color: var(--color-danger-800);
}

.btn-outline.btn-danger:hover,
.btn-outline.btn-danger:active,
.btn-outline.btn-danger:focus {
  background-color: var(--color-danger-800);
  color: var(--color-white);
}

/* Buttons in containers */

.page-action-buttons-row {
  margin-bottom: 6px;
}

.row.buttons li {
  font-size: 1rem;
}

.row.buttons > a,
.row.dotbuttons > a {
  margin-left: 0.88em;
}

/*
  FIXME:
  btn-primary btn-border -> text-primary border border-primary bg-transparent
  btn-success btn-border -> text-success border border-success bg-transparent
  btn-warning btn-border -> text-warning border border-warning bg-transparent
  btn-info btn-border -> text-info border border-info bg-transparent
  btn-danger btn-border -> text-danger border border-danger bg-transparent
*/

@media (max-width: 992px) {
  .page-action-buttons-row:last-child .action-button:last-child {
    border-bottom-width: 0;
  }
}

/* Buttons from 3rd party widgets */

.facetly-button.facetly-button-success {
  background-color: transparent;
  border-color: var(--color-success-800);
  color: var(--color-success-800);
}

.facetly-button.facetly-button-success:hover,
.facetly-button.facetly-button-success:focus {
  background-color: var(--color-success-800) !important;
  color: var(--color-white) !important;
}

.facetly-button.facetly-button-danger {
  background-color: transparent;
  border-color: var(--color-danger-800);
  color: var(--color-danger-800);
}

.facetly-button.facetly-button-danger:hover,
.facetly-button.facetly-button-danger:focus {
  background-color: var(--color-danger-800) !important;
  color: var(--color-white) !important;
}

@media (prefers-contrast: more) {
  .btn-primary {
    background-color: var(--color-primary-800);
    border-color: var(--color-primary-900);
  }

  .btn-primary:hover,
  .btn-primary:active,
  .btn-primary:focus {
    background-color: var(--color-primary-900);
  }

  .btn-info {
    background-color: var(--color-info-800);
    border-color: var(--color-info-900);
  }

  .btn-info:hover,
  .btn-info:active,
  .btn-info:focus {
    background-color: var(--color-info-900);
  }

  .btn-warning {
    background-color: var(--color-warning-800);
    border-color: var(--color-warning-900);
  }

  .btn-warning:hover,
  .btn-warning:active,
  .btn-warning:focus {
    background-color: var(--color-warning-900);
  }

  .btn-danger {
    background-color: var(--color-danger-800);
    border-color: var(--color-danger-900);
  }

  .btn-danger:hover,
  .btn-danger:active,
  .btn-danger:focus {
    background-color: var(--color-danger-900);
  }

  .btn-success {
    background-color: var(--color-success-800);
    border-color: var(--color-success-900);
  }

  .btn-success:hover,
  .btn-success:active,
  .btn-success:focus {
    background-color: var(--color-success-900);
  }
}
